import { useState } from "react";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useLocation, useNavigate } from "react-router-dom";

import StockInfo from "layouts/pages/stock/new stock/componets/StockInfo";

// Newlab layout schemas for form and form feilds
import validations from "layouts/pages/stock/new stock/schemas/validations";
import form from "layouts/pages/stock/new stock/schemas/form";
import initialValues from "layouts/pages/stock/new stock/schemas/initialValues";
import axios from "axios";
import MedRequisition from "layouts/pages/stock/new stock/componets/MedRequisition";

function getSteps() {
  return ["Medical Requisition"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <MedRequisition formData={formData} />;

    default:
      return null;
  }
}

function CreateMedRequisition() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const params = new URLSearchParams(location.search);
  console.log("location", location);
  const id = params.get("id");
  console.log("hii", id);
  // useEffect(() => {
  //   if (id == null) {
  //     setRoleName("");
  //     setRoleDescription("");
  //   }
  // }, [id]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    console.log("hii", values);
    const getData = {
      mmuid: "",
      mmuitemcode: values.MMUItemCode,
      mrnumber: "",
      sapitemcode: values.SAPItemCode,
      uom: values.UOM,
      whid: values.WHID,
      approvedby: "",
      approveddate: "",
      blockid: values.blockID,
      createdby: "",
      createddate: null,
      documentofproof: "",
      id: null,
      itemtype: values.itemType,
      latlong: "",
      location: null,
      mmucode: values.mmuCode,
      modifiedby: "",
      modifieddate: null,
      name: values.name,
      quantityapproved: "",
      quantitypndg: "",
      quantityrcvd: "",
      quantityrqst: values.quantityRqst,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "SYNCED",
    };
    await axios
      .post("http://161.97.136.16:9136/api/Stock/v1/create", getData)

      .then((response) => {
        console.log("gfgfgf", response);
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/pages/stock/stock-list");

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      {/* <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper> */}
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Medical Requisition
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            {id ? "Update" : "submit"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CreateMedRequisition;
